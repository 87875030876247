export const NoProduct = require("../assets/images/empty-basket.svg");
export const FoodTagVeg = require("../assets/images/Vector.png");
export const FoodTagNonVeg = require("../assets/images/NonVegfoodTag.svg");
export const Banner1 = require("../assets/images/SideBanner1.jpg");
export const Banner2 = require("../assets/images/SideBanner2.jpg");
export const Banner3 = require("../assets/images/SideBanner3.png");
export const Banner4 = require("../assets/images/SideBanner4.png");
export const modalCloseIcon = require("../assets/images/modalClose.svg");
export const closeIcon = require("../assets/images/closeIcon.svg");
export const rewardIcon = require("../assets/images/star.svg");
export const arrowLeft = require("../assets/images/arrow-left.svg");
export const calendaricon = require("../assets/images/calendaricon.png");
export const timer = require("../assets/images/timer.png");
